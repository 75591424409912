import { Injectable } from "@angular/core";
@Injectable()
export class Store {

  static select(name: string) {
    return JSON.parse(localStorage.getItem(name) as string);
  }

  static set(name: string, value: any) {
    localStorage.setItem(name, JSON.stringify(value));
  }

  static hasToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as string);
    return currentUser && currentUser.token;
  }

  static getToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as string);
    if (currentUser) {
      return currentUser.token;
    } else {
      return '';
    }
  }

  static getId() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as string);
    if (currentUser) {
      return currentUser.id;
    } else {
      return '';
    }
  }

  static getTypeAccount() {
    const typeUser = JSON.parse(localStorage.getItem('type') as string);
    if (typeUser) {
      return typeUser;
    } else {
      return '';
    }
  }

  static getUserName() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as string);
    if (currentUser) {
      return currentUser.name;
    } else {
      return '';
    }
  }

  static getUserEmail() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as string);
    if (currentUser) {
      return currentUser.email;
    } else {
      return '';
    }
  }

}
